import React, { type FC } from 'react';
import ReactSlider from 'react-slick';
import Recommendations from '..';
import Styles from '../../styles.scss';
import type { CardContainerProps } from '../../types';

type SliderProps = Omit<CardContainerProps, 'algorithmType'>;

const sliderSettings = () => ({
	arrows: false,
	centerMode: false,
	className: `${Styles.mobileSliderSmCards} mobile-slider-sm-cards`,
	dots: false,
	infinite: false,
	slidesToScroll: 1,
	slidesToShow: 1,
	speed: 500,
});

const Slider: FC<SliderProps> = (props) => (
	<ReactSlider {...sliderSettings()}>
		<Recommendations {...props} />
	</ReactSlider>
);

export default Slider;
