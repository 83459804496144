import { useIsVisible } from '@hooks/useIsVisible';
import { queueVendorImpression } from '@redux/vendorImpressions/thunks';
import { ClassesRecord, compose, replace } from '@xo-union/react-css-modules';
import {
	ActionsOverlay,
	Heart,
	HeartAnimationTypes,
	MediumVendorCard,
	SmallVendorCard,
	ThemeProvider,
	ThemeProviderProps,
	VendorCard as UnionVendorCard,
	VendorCardClasses,
	VendorCardProps,
} from '@xo-union/tk-component-vendor-card';
import { useMedia } from '@xo-union/tk-ui-breakpoints';
import classNames from 'classnames';
import omitBy from 'lodash/omitBy';
import React, { ReactNodeArray, useMemo } from 'react';
import { connect } from 'react-redux';
import { FCWithChildren } from 'types/react-extended';
import WideVendorCard from '../WideVendorCard';
import desktopHeartStyles from './heartStyles.scss';
import vendorCardProps from './helpers/vendor_card_props';
import Styles from './styles.scss';

interface ComponentProps {
	cardUiLocation?: string | null;
	cardProps: VendorCard.CardProps;
	children?: ReactNodeArray;
	vendorCardClasses?: Partial<ClassesRecord<VendorCardClasses>>;
	handleSave?: (vendor: Vendor.Raw) => void;
	impressionDetails?: VendorImpressions.ImpressionDetails | null;
	isSaved?: boolean;
	onClick?: (event: MouseEvent) => void;
	queueImpression: (impression: VendorImpressions.ImpressionDetails) => void;
	showHeart?: boolean;
	type?: string;
	vendor: Vendor.Raw;
}

const defaultChildren: ReactNodeArray = [];

const defaultProps: Utils.PickOptional<ComponentProps> = {
	cardUiLocation: '',
	vendorCardClasses: {},
	children: defaultChildren,
	handleSave: () => {
		// Do Nothing
	},
	impressionDetails: null,
	isSaved: false,
	onClick: () => {
		// Do Nothing
	},
	showHeart: false,
	type: 'sm',
};

export type Props = ComponentProps & typeof defaultProps;

export const Card: FCWithChildren<Props> = (props) => {
	const {
		vendorCardClasses,
		handleSave,
		impressionDetails,
		isSaved,
		onClick,
		queueImpression,
		type,
		vendor,
		showHeart,
		cardProps,
		children,
		cardUiLocation,
	} = props;

	const isXxl = useMedia('(min-width: 1360px)');
	const isXl = useMedia('(min-width: 1192px)');
	const isLg = useMedia('(min-width: 1024px)');
	const screenSizes = [isXxl.yes, isXl.yes, isLg.yes];

	const callback = () => {
		if (impressionDetails) {
			queueImpression(impressionDetails);
		}
	};
	const [targetRef] = useIsVisible({ callback });

	const VendorCard = useMemo(() => {
		switch (type) {
			case 'sm':
				return SmallVendorCard;
			case 'wide':
				return WideVendorCard;
			case 'md':
				return MediumVendorCard;
			default:
				return UnionVendorCard;
		}
	}, [type]);

	const smallClassName = useMemo(() => {
		if (isXxl.yes) {
			return Styles.smCardXxlWindow;
		}
		if (isXl.yes) {
			return Styles.smCardXlWindow;
		}
		if (isLg.yes) {
			return Styles.smCardLgWindow;
		}
		return undefined;
	}, screenSizes);

	const sectionClassName = useMemo(() => {
		switch (type) {
			case 'sm': {
				const cl = classNames(Styles.sectionContainer, smallClassName);
				return cl;
			}
			case 'wide':
			case 'md':
				return Styles.sectionContainer;
			default:
				return classNames(
					Styles.sectionContainer,
					Styles.unionSectionContainer,
				);
		}
	}, [type, ...screenSizes]);

	const defaultVendorProps: VendorCardProps = vendorCardProps(vendor);
	const themeAttrs: ThemeProviderProps = {
		theme: replace(desktopHeartStyles),
	};
	const classes = compose(vendorCardClasses);
	const cardPropsOmitted = omitBy(cardProps, (value) => value === undefined);

	return (
		<section ref={targetRef} className={sectionClassName}>
			<VendorCard
				id={vendor.id}
				onClick={onClick}
				classes={classes}
				{...defaultVendorProps}
				{...cardPropsOmitted}
			>
				{
					// small vendor cards don't accept ActionOverlays/Heart children
					// and  business logic: only show heart for search page vendor cards
					type !== 'sm' && showHeart ? (
						<ActionsOverlay>
							<ThemeProvider {...themeAttrs}>
								<Heart
									animated
									animationType={HeartAnimationTypes.BURST}
									filled={isSaved}
									id={vendor.id}
									onClick={() => handleSave(vendor)}
								/>
							</ThemeProvider>
						</ActionsOverlay>
					) : null
				}
				{children}
			</VendorCard>
		</section>
	);
};

Card.defaultProps = defaultProps;

const mapDispatchToProps = {
	queueImpression: queueVendorImpression,
};

export default connect(null, mapDispatchToProps)(Card);
