import template from 'lodash/template';
import headers from '../../constants/headers';

export default (vendor, algorithm) => {
	const { categoryCode } = vendor;

	const headerText = headers[categoryCode] && headers[categoryCode][algorithm];
	if (headerText) {
		return headerText;
	}

	const compiledHeader = template(headers.DEFAULT[algorithm]);
	const category = vendor.categorySingularTerm.toLowerCase();
	const categories = vendor.categoryPluralTerm.toLowerCase();
	const defaultText = compiledHeader({ category, categories });
	if (defaultText) {
		return defaultText;
	}

	return '';
};
