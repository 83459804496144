import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Waypoint from 'react-waypoint';

import { AnalyticsContext } from '../../contexts/AnalyticsContext/AnalyticsContext';

export class TrackableContainer extends Component {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.any,
		trackingData: PropTypes.object.isRequired,
		bottomOffset: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		children: null,
		bottomOffset: '50%',
	};

	constructor(props) {
		super(props);
		this.state = {
			hasViewed: false,
		};
	}

	handleOnEnterViewport = () => {
		if (this.state.hasViewed) {
			return;
		}

		this.context.track(this.props.trackingData);
		this.setState({ hasViewed: true });
	};

	render() {
		const { className, bottomOffset, children } = this.props;
		/* topOffset didn't have any effect so per
       https://github.com/brigade/react-waypoint/issues/165
       bottomOffset seems to work */
		return (
			<Waypoint
				onEnter={this.handleOnEnterViewport}
				className={className}
				bottomOffset={bottomOffset}
			>
				<div>{children}</div>
			</Waypoint>
		);
	}
}

TrackableContainer.contextType = AnalyticsContext;

export default TrackableContainer;
