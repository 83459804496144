import colors from '@xo-union/tk-ui-colors/lib/variables.css';
import React, {
	FunctionComponent,
	useContext,
	CSSProperties,
	useEffect,
} from 'react';

import { Context } from './Frame';
import styles from './styles.scss';

interface Props {
	className?: string;
	style?: CSSProperties;
	freeze?: boolean;
}

const Bone: FunctionComponent<Props> = (props) => {
	const { freeze, style } = props;
	const parentContext = useContext(Context);

	useEffect(() => {
		if (parentContext === 'notWrapped') {
			console.warn(
				'The Skeleton Bone should be wrapped with a Skeleton Frame to ensure proper ARIA labels.',
			);
		}
	}, []);

	const background = `linear-gradient(to right, ${colors.varCoolgray300}, ${colors.varCoolgray200}, ${colors.varCoolgray300})`;
	const className = `${freeze ? '' : styles.bone} ${props.className || ''}`;

	return (
		<div
			className={className}
			style={{
				background: freeze ? undefined : background,
				backgroundColor: freeze ? colors.varCoolgray100 : undefined,
				backgroundSize: '1000px 100%',
				...style,
			}}
		/>
	);
};

export default Bone;
