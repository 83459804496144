export default {
	REC: 'WPH',
	WPH: 'VID',
	BWP: 'BEA',
	WCK: 'CAT',
	FLO: 'DJS',
	CAT: 'RNT',
	BEA: 'FAV',
	PLN: 'WPH',
	DJS: 'BDS',
};
