import axios from 'axios';
import { conversationsApi, conversationsAuthApi } from '../../settings';

export async function getJwtAuth(basicHeaders, membershipSessionToken) {
	try {
		const authResponse = await axios.post(
			conversationsAuthApi,
			{},
			{
				headers: {
					...basicHeaders,
					'membership-session-token': membershipSessionToken,
				},
			},
		);

		return authResponse.data;
	} catch (e) {
		// ignoring membership errors (eg. expired token)
	}

	return undefined;
}

export default async function conversations(memberId, membershipSessionToken) {
	const basicHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
	const jwtAuth = await getJwtAuth(basicHeaders, membershipSessionToken);

	if (jwtAuth) {
		const response = await axios.get(`${conversationsApi}/${memberId}`, {
			headers: {
				...basicHeaders,
				Authorization: `Bearer ${jwtAuth}`,
			},
		});

		return response.data.conversations;
	}

	return {};
}
