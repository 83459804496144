import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import AnalyticsEvents from '../../../../../constants/analytics';

export type ActionType = 'click set password' | 'save password';

export const useTrackPostLeadInteraction = (
	userId: string | undefined,
	anonymousId: string,
) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(action: ActionType, sourceContent: string) => {
			track({
				event: AnalyticsEvents.POST_LEAD_INTERACTION,
				properties: {
					action,
					anonymousId,
					userId,
					sourceContent,
				},
			});
		},
		[track, anonymousId, userId],
	);
};
