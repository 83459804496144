import Icon from '@xo-union/tk-component-icons';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Styles from './styles.scss';

class Link extends PureComponent {
	static propTypes = {
		iconClassName: PropTypes.string,
		icon: PropTypes.string,
		iconSize: PropTypes.oneOf(['sm', 'md', 'lg']),
		onClick: PropTypes.func,
		text: PropTypes.string,
		title: PropTypes.string,
		websiteUrl: PropTypes.string.isRequired,
	};

	static defaultProps = {
		text: '',
		iconClassName: '',
		title: '',
		icon: '',
		iconSize: null,
		onClick: () => {},
	};

	hasLink() {
		const { websiteUrl } = this.props;
		return !!websiteUrl;
	}

	renderIcon() {
		const { icon, iconSize, iconClassName } = this.props;
		return (
			icon && <Icon name={icon} size={iconSize} className={iconClassName} />
		);
	}

	render() {
		const { onClick, websiteUrl, title, text } = this.props;

		return (
			this.hasLink() && (
				<span className={Styles.link}>
					<a
						href={websiteUrl}
						title={title}
						target="_blank"
						rel="noopener noreferrer nofollow"
						onClick={onClick}
					>
						{this.renderIcon()}
						{text}
					</a>
				</span>
			)
		);
	}
}

export default Link;
