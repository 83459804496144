import React from 'react';
import { List, Slider } from './Recommendations/containers';

import type { CardContainerProps } from './types';

const defaultProps = {
	recommendedVendors: [] as Vendor.Raw[],
	displayTheme: '',
	cardType: 'sm',
	slider: false,
};

export type Props = CardContainerProps & typeof defaultProps;

export const CardContainer = (props: Props) =>
	props.slider ? <Slider {...props} /> : <List {...props} />;

export default CardContainer;
