import React, { createContext, CSSProperties } from 'react';
import { FCWithChildren } from 'types/react-extended';

interface Props {
	className?: string;
	style?: CSSProperties;
	ariaLabel?: string;
}

type ContextValue = 'isWrapped' | 'notWrapped';

export const Context = createContext<ContextValue>('notWrapped');

const Frame: FCWithChildren<Props> = (props) => {
	const { ariaLabel, children, style, className } = props;
	const label = ariaLabel || 'Loading...';

	return (
		<Context.Provider value="isWrapped">
			<div
				role="progressbar"
				aria-busy="true"
				aria-label={label}
				style={style}
				className={className}
			>
				{children}
			</div>
		</Context.Provider>
	);
};

export default Frame;
