import { connect } from 'react-redux';

import Link from './components';

export function mapStateToProps(state) {
	return {
		vendor: state.vendor,
		viewport: state.viewport,
	};
}

export default connect(mapStateToProps)(Link);
