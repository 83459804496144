import { determineReferredMarketCode } from '@utils/index';
import { isFromSearchPage } from '@utils/regex';
import merge from 'deepmerge';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnalyticsConstants from '../../../constants/analytics';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../constants/cookies';
import { AnalyticsContext } from '../../contexts/AnalyticsContext/AnalyticsContext';
import Vendor from '../../decorators/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';
import { cookieGetItem } from '../../utils/cookie/consentManagementCookie';
import Card from '../card';

export class TrackableCard extends Component {
	vendorAnalytics = new VendorAnalytics(new Vendor(this.props.vendor));

	displayLocationName() {
		const { location } = this.props;

		if (location == null) {
			return '';
		}

		return `${location.city}, ${location.stateCode}`;
	}

	handleClick = () => {
		const { isReferred, marketCode, trackingData, cardUiLocation, storefront } =
			this.props;
		const cookieMarketCode = cookieGetItem(VENDOR_REFERRED_MARKET_CODE);
		const isFromSearch = isFromSearchPage(this.props.referrer);
		const trackingProps = {
			...trackingData.properties,
			vendorReferredMarketCode: determineReferredMarketCode({
				cookieMarketCode,
				isFromSearch,
				isReferred,
				marketCode,
			}),
			...(cardUiLocation === 'vendor network' && {
				sourceContent: 'vendor network',
				sourcePage: storefront.isPaid ? 'paid storefront' : 'unpaid storefront',
			}),
		};

		this.context.track({
			event: AnalyticsConstants.CLICK_THROUGH_TO_VENDORS,
			properties: merge(this.vendorAnalytics.baseEventProps(), trackingProps),
		});
	};

	render() {
		const {
			cardProps,
			impressionDetails,
			vendorCardClasses,
			index,
			handleSave,
			isSaved,
			showHeart,
			type,
			vendor,
			membership,
			cardUiLocation,
		} = this.props;

		return (
			<Card
				cardUiLocation={cardUiLocation}
				cardProps={cardProps}
				handleSave={handleSave}
				impressionDetails={impressionDetails}
				index={index}
				isSaved={isSaved}
				onClick={this.handleClick}
				showHeart={showHeart}
				type={type}
				vendor={vendor}
				vendorCardClasses={vendorCardClasses}
			>
				{this.props.children}
			</Card>
		);
	}
}

TrackableCard.propTypes = {
	cardUiLocation: PropTypes.string,
	cardProps: PropTypes.object,
	children: PropTypes.node,
	device: PropTypes.string.isRequired,
	handleSave: PropTypes.func.isRequired,
	impressionDetails: PropTypes.shape({
		locationIndex: PropTypes.number.isRequired,
		impressionType: PropTypes.string.isRequired,
		city: PropTypes.string,
		vendor: PropTypes.shape().isRequired,
	}).isRequired,
	index: PropTypes.number,
	isReferred: PropTypes.bool.isRequired,
	isSaved: PropTypes.bool,
	location: PropTypes.object.isRequired,
	marketCode: PropTypes.string.isRequired,
	membership: PropTypes.object,
	referrer: PropTypes.string.isRequired,
	showHeart: PropTypes.bool,
	trackingData: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	vendor: PropTypes.object.isRequired,
	vendorCardClasses: PropTypes.object,
	storefront: PropTypes.object.isRequired,
};

TrackableCard.defaultProps = {
	cardUiLocation: null,
	cardProps: {},
	children: null,
	device: 'desktop',
	handleSave: () => {},
	isSaved: false,
	membership: {},
	showHeart: false,
	type: 'sm',
	variationAlgorithm: '',
	vendor: {},
	vendorCardClasses: {},
};

TrackableCard.contextType = AnalyticsContext;

export function mapStateToProps(state) {
	return {
		device: state.config.device || '',
		isReferred: state.settings.isReferred,
		location: state?.location || null,
		marketCode: state.settings.marketCode,
		membership: state.membership,
		referrer: state.requestInfo.info.referrer,
		storefront: state.vendor.vendor,
	};
}

export default connect(mapStateToProps)(TrackableCard);
