export const categoryMediaMappings = {
	REC: {
		id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		name: 'Reception Venues',
		mediaId: 'def22e0f-0486-4186-a98c-375091b6d186',
	},
	WPH: {
		id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		name: 'Wedding Photographers',
		mediaId: '609cc936-26c5-4a64-a4d9-3983adfe8a22',
	},
	BWP: {
		id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		name: 'Bridal Salons',
		mediaId: '433702d4-c799-4ffa-a271-c81656c07814',
	},
	DJS: {
		id: '37ffe21a-579f-4100-88c1-ca664f5398ce',
		name: 'DJs',
		mediaId: '212789e1-4ac3-4ba6-8aac-0bb47acdb0b6',
	},
	FLO: {
		id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		name: 'Florists',
		mediaId: '46c5b0b9-3e79-4117-82fe-34ebabe7cad2',
	},
	PLN: {
		id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		name: 'Wedding Planners',
		mediaId: '5f0544f7-cbc4-47be-b7e8-0f373f412a64',
	},
	HRB: {
		id: '1ac827b7-4bc5-4711-a454-896a9a7016f7',
		name: 'Hotel Room Blocks',
		mediaId: '95b0d5c6-e7f0-44b5-a636-1674a028829d',
	},
	JWL: {
		id: 'eac11ae2-358a-428f-bf57-e3dfac5c0b56',
		name: 'Jewelers',
		mediaId: '4c2e0a96-271f-4d8f-b43d-9a517eac3c66',
	},
	BEA: {
		id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		name: 'Beauty',
		mediaId: '3742d86e-1b5f-42a8-9b7d-0b0d819d79f1',
	},
	VID: {
		id: '4c1e13e9-d500-4f3f-870e-d919beda43cf',
		name: 'Videographers',
		mediaId: 'd5bcf93b-4825-4db4-b7db-47948d1833f4',
	},
	BDS: {
		id: '92520084-6d29-49e9-a9ab-3b2daef7fa00',
		name: 'Wedding Bands',
		mediaId: 'bfaab7d8-79d5-441d-a1e8-f662f04bb519',
	},
	WCK: {
		id: '0908dd90-3052-4a20-801e-351af3ec9334',
		name: 'Wedding Cakes',
		mediaId: 'fab89bfe-e40d-46a9-8262-9d73354f71fd',
	},
	ACC: {
		id: '91a28f7b-572d-46ab-8ef0-64a18f9b6886',
		name: 'Accessories',
		mediaId: '52ade8ba-bd3c-4944-9ba4-aa36636c7192',
	},
	ALP: {
		id: 'e42a9c31-328c-497b-bafb-45afdc583b4e',
		name: 'Alterations + Preservation',
		mediaId: 'acee3540-d7da-4345-958d-873bd731b273',
	},
	BAR: {
		id: '0becf958-f2f3-42e0-ab89-89a90128b0ad',
		name: 'Bar Services',
		mediaId: 'b918db6f-fbfe-4ef8-9bf0-88ef4e91ed6d',
	},
	BNB: {
		id: '5f5f2cd1-da58-448d-9d7e-0ec5f1d46ebd',
		name: 'Bed and Breakfasts',
		mediaId: 'febff3e8-f8c0-42a4-aa00-c0651f598b3a',
	},
	BPH: {
		id: '3a7b2ad5-373d-4f3e-838a-4db818007d1c',
		name: 'Boudoir Photographers',
		mediaId: '391bbeec-a196-4298-8ea0-11d3b1abf63d',
	},
	CAL: {
		id: '341f2723-a9f1-4571-a0f8-5cf22b8b396c',
		name: 'Calligraphers',
		mediaId: '9ead4373-e3fd-47eb-8c3c-4e1769ccd73a',
	},
	CAT: {
		id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		name: 'Caterers',
		mediaId: '4f846a77-a850-472d-91ac-fcdfa00b380a',
	},
	CAC: {
		id: '5f1d05c2-a5d3-40f2-8574-eac63dd2879b',
		name: 'Ceremony Accessories',
		mediaId: '159839fc-fd17-4e63-9744-a06e493728fa',
	},
	CER: {
		id: 'e1c95d05-7b35-4ec3-9312-1e47d33300c3',
		name: 'Ceremony Venues',
		mediaId: 'e86d10cd-dfc1-4b98-be72-fe881e9b4356',
	},
	DNC: {
		id: '80568279-f0e5-447f-a1c7-849c3d3daecc',
		name: 'Dance Lessons',
		mediaId: 'abcb3f5c-c93c-4c1a-8139-8f2f2f0d6f60',
	},
	DEC: {
		id: '2bd88708-6937-4dc2-8c07-18f7787e0278',
		name: 'Decor',
		mediaId: 'c2b32dd3-0943-4193-877b-dacf5eedb683',
	},
	DST: {
		id: 'a67e28e0-73be-4080-85c2-3daac7c2d8fc',
		name: 'Desserts',
		mediaId: 'e02de763-d19c-427e-b1be-6deac3331aea',
	},
	ENS: {
		id: '5fa111c5-38f4-4a7c-8519-0c94510319af',
		name: 'Ensembles + Soloists',
		mediaId: '264cad27-f5d1-4c1f-91b9-5b1b220990c5',
	},
	FAV: {
		id: 'a96b81d5-139d-4423-bb9b-08505bb99b57',
		name: 'Favors + Gifts',
		mediaId: '5090ff7a-29d9-4072-af0a-0544e070baef',
	},
	FIT: {
		id: '4aa55a31-a72f-4895-a6d5-0bcb4aeae987',
		name: 'Fitness',
		mediaId: '38abe207-60e4-41d4-81a6-244f2a040ced',
	},
	INV: {
		id: '1c0c8116-66ba-4a66-89b5-24807ed0271f',
		name: 'Invitations + Paper Goods',
		mediaId: '629dad65-4b3d-409c-b1a2-83ff0253ce9b',
	},
	LIT: {
		id: '804f4fab-9050-4d4a-ae8c-7cd26aa93388',
		name: 'Lighting',
		mediaId: '3d38737b-f263-43d1-911a-b6ac705ac34c',
	},
	MEN: {
		id: '6dcc0428-f240-4bc5-9a16-b97a0115c3b5',
		name: 'Menswear',
		mediaId: '08710f24-5f2e-49a9-bc29-ccae289946e1',
	},
	NLW: {
		id: 'fe8b3787-13d5-463b-9a06-f911bf7d6211',
		name: 'Newlywed Services',
		mediaId: 'c1f3478d-597a-4b6b-a20e-61ef5d64c381',
	},
	OPC: {
		id: '71445a6f-4d18-4ce6-ba6f-0f8372e50ecf',
		name: 'Officiants + Premarital Counseling',
		mediaId: 'da6a4d37-c9b0-48bb-a099-f8ea18bcc906',
	},
	PHB: {
		id: '1c9b823f-d017-4f17-b8f6-979a29298562',
		name: 'Photo Booths',
		mediaId: '881497b9-f9e0-44fa-a6ae-d7b2c7230741',
	},
	REG: {
		id: 'eff27067-17de-4671-b5bc-b44274d38808',
		name: 'Registry Services',
		mediaId: '8216b95b-27fa-459d-a90b-d518ecfbe043',
	},
	SPV: {
		id: 'ca8f12c7-dc25-4e88-a403-590db8469230',
		name: 'Rehearsal Dinners, Bridal Showers + Parties',
		mediaId: '86434b8e-e303-4be2-bc41-6ffdf74819e4',
	},
	RNT: {
		id: '540cb3db-2970-418e-834e-329e6e2f499c',
		name: 'Rentals',
		mediaId: '694c85c6-99c9-4ac0-8fa4-c79913ea9d37',
	},
	SVS: {
		id: '00038b55-959b-4b69-9c9a-2a8f14f454a0',
		name: 'Service Staff',
		mediaId: 'fe77b083-c664-4d6b-815f-10b606c7b142',
	},
	TEC: {
		id: 'faa3afa1-a302-4b22-b4e3-a00e996d0402',
		name: 'Technology',
		mediaId: '91c5eaa2-0d75-4556-8077-18d3292f9297',
	},
	TRA: {
		id: '8f642158-4f07-4a37-8fba-2ed909d224c4',
		name: 'Transportation',
		mediaId: '8e7b2c6b-137e-42c9-98e1-b8a2b63a5af4',
	},
	TRV: {
		id: '6b48ed71-35f4-4bb0-a501-3891bf37f247',
		name: 'Travel Specialists',
		mediaId: 'c09e8117-13e5-4616-a47d-dd143795d963',
	},
	VHV: {
		id: '5f053568-2b79-4cc2-a7d9-eb28c9eac077',
		name: 'Vacation Homes + Villas',
		mediaId: '13fe2f63-c5e0-4f8b-9be5-92d518658cde',
	},
	VAR: {
		id: '5d65d5f8-495f-4d05-8dca-2d3606030965',
		name: 'Variety Acts',
		mediaId: '04103536-fb7d-483b-8435-1e485a7d55d4',
	},
	DES: {
		id: '273eb556-0b4d-4330-af66-e37faa1b0dd2',
		name: 'Wedding Designers',
		mediaId: 'b1ecea28-85d5-4178-aab4-36a78a6ef73d',
	},
	WJW: {
		id: '09ec68e5-6f82-427f-9e2d-0f045fde9088',
		name: 'Wedding Jewelry',
		mediaId: '58239dbc-e935-4078-a65e-cb1c629b486d',
	},
};

export default categoryMediaMappings;
