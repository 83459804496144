import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AnalyticsConstants from '../../../../constants/analytics';
import TrackableContainer from '../../../components/trackable_container';
import Vendor from '../../../decorators/vendor';
import constants from '../constants';
import algorithmAnalytics from '../constants/algorithm_analytics';
import algorithms from '../constants/algorithms';
import crossCatMapping from '../constants/cross_cat_mapping';
import headerText from '../helpers/header_text';
import seeAllUrl from '../helpers/see_all_url';
import CardContainer from './card_container';
import { Wrapper } from './wrapper';

export const WidgetContext = React.createContext('unknown');

export class WidgetContainer extends Component {
	static propTypes = {
		algorithmType: PropTypes.oneOf(['SIMILAR', 'BOW', 'PVREC_CROSS_CAT'])
			.isRequired,
		cardType: PropTypes.oneOf(['sm', 'md', 'lg']),
		className: PropTypes.string,
		fetchBowVendors: PropTypes.func.isRequired,
		fetchPvRecCrossCatVendors: PropTypes.func.isRequired,
		fetchSimilarVendors: PropTypes.func.isRequired,
		impressionType: PropTypes.oneOf(
			Object.values(AnalyticsConstants.widgetImpressionType),
		).isRequired,
		member: PropTypes.object,
		recommendedVendors: PropTypes.arrayOf(PropTypes.object).isRequired,
		pvRec2point0Assignment: PropTypes.string.isRequired,
		vendor: PropTypes.object.isRequired,
		viewport: PropTypes.object,
		widgetName: PropTypes.string,
	};

	static defaultProps = {
		member: null,
		vendor: {},
		recommendedVendors: [],
		cardType: 'sm',
		className: '',
		widgetName: '',
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.member !== this.props.member) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const {
			vendor,
			fetchSimilarVendors,
			fetchBowVendors,
			fetchPvRecCrossCatVendors,
			pvRec2point0Assignment,
		} = this.props;
		const algorithm = this.algorithm();

		switch (algorithm) {
			case algorithms.SIMILAR:
				fetchSimilarVendors(vendor);
				break;
			case algorithms.BOW:
				fetchBowVendors(vendor);
				break;
			case algorithms.PVREC_CROSS_CAT:
				fetchPvRecCrossCatVendors(
					vendor.id,
					this.crossCategory(),
					pvRec2point0Assignment,
				);
				break;
			default:
				throw new Error('No Algorithm provided to the Vendor Widget');
		}
	};

	algorithm() {
		const { algorithmType } = this.props;

		return algorithmType;
	}

	header() {
		const { vendor } = this.props;
		return headerText(vendor, this.algorithm());
	}

	noValidAlgorithmOrNoResults() {
		const { recommendedVendors } = this.props;
		// recommendedVendors is an array with objects of {algorithmType: [recommendedVendors]}
		// Example:
		// recommendedVendors: [{SIMILARITY: [vendor1, vendor2]}]
		const algorithmType = this.algorithm();
		// if recommendation algorithm has less than 4 results, we don't want to show the widget
		return (
			!recommendedVendors[algorithmType] ||
			recommendedVendors[algorithmType].length <
				constants.DEFAULT_VENDOR_LIMIT_SHOW
		);
	}

	widgetViewedTrackingData() {
		return {
			...this.commonTrackingData(),
			event: AnalyticsConstants.WIDGET_VIEWED,
		};
	}

	crossCategory() {
		const { vendor } = this.props;

		return crossCatMapping[vendor.categoryCode];
	}

	commonTrackingData() {
		const { recommendedVendors } = this.props;
		const recommendedVendor = new Vendor(
			recommendedVendors[this.algorithm()][0],
		);
		const analytics = algorithmAnalytics[this.algorithm()];

		return {
			properties: {
				pagePosition: analytics.position,
				sourceContent: 'recommended vendors',
				sourcePage: 'unpaid storefront',
				sourceChannel: 'internal',
				sourceFormula: analytics.name,
				widgetName: analytics.name,
				vendorCategory: recommendedVendor.categoryCode,
				vendorCount: recommendedVendors[this.algorithm()].length,
			},
		};
	}

	getWidgetType(impressionType, cardType) {
		const { widgetImpressionType } = AnalyticsConstants;
		switch (impressionType) {
			case widgetImpressionType.STOREFRONT_RIGHT_RAIL: {
				return 'right';
			}
			case widgetImpressionType.STOREFRONT_CENTER: {
				return cardType === 'md' ? 'center' : 'right-rail';
			}
			case widgetImpressionType.STOREFRONT_BOTTOM: {
				return 'bottom';
			}
			case widgetImpressionType.STOREFRONT_TOP: {
				return 'top';
			}
			case widgetImpressionType.DIRECTORY_RIGHT_RAIL: {
				return 'right-rail';
			}
		}
	}

	sliderContainer(isMobile, impressionType) {
		const { STOREFRONT_CENTER, STOREFRONT_BOTTOM } =
			AnalyticsConstants.widgetImpressionType;

		if (
			isMobile &&
			[STOREFRONT_CENTER, STOREFRONT_BOTTOM].includes(impressionType)
		) {
			return false;
		}

		return isMobile;
	}

	render() {
		const {
			impressionType,
			vendor,
			recommendedVendors,
			cardType,
			className,
			algorithmType,
			viewport,
			widgetName,
		} = this.props;
		if (this.noValidAlgorithmOrNoResults()) {
			return null;
		}

		const widgetType = this.getWidgetType(impressionType, cardType);

		return (
			<WidgetContext.Provider value={widgetType}>
				<TrackableContainer trackingData={this.widgetViewedTrackingData()}>
					<Wrapper
						className={className}
						headerText={this.header()}
						seeAllUrl={seeAllUrl(
							this.algorithm(),
							vendor,
							recommendedVendors[this.algorithm()][0],
						)}
						cardType={cardType}
						trackingData={this.commonTrackingData()}
						algorithmType={algorithmType}
						widgetType={widgetType}
						widgetName={widgetName}
					>
						<CardContainer
							algorithmType={algorithmType}
							impressionType={impressionType}
							recommendedVendors={recommendedVendors[this.algorithm()]}
							cardType={cardType}
							trackingData={this.commonTrackingData()}
							slider={this.sliderContainer(viewport.isMobile, impressionType)}
							widgetName={widgetName}
						/>
					</Wrapper>
				</TrackableContainer>
			</WidgetContext.Provider>
		);
	}
}

export default WidgetContainer;
