export default {
	REC: {
		SIMILAR: 'Other Venues Nearby',
		BOW: 'Popular with couples in your area',
	},
	WPH: {
		SIMILAR: 'Popular with couples in your area',
		BOW: 'Top-rated photographers',
	},
	BWP: {
		SIMILAR: 'Other Bridal Salons Nearby',
		BOW: 'Popular with couples in your area',
	},
	WCK: {
		BOW: 'Top-rated wedding cake bakeries',
	},
	FLO: {
		BOW: 'Top-rated florists',
	},
	CAT: {
		BOW: 'Top-rated caterers',
	},
	BEA: {
		BOW: 'Top-rated beauty salons',
	},
	PLN: {
		BOW: 'Top-rated wedding planners',
	},
	DJS: {
		BOW: 'Top-rated DJs',
	},
	DEFAULT: {
		SIMILAR: 'Popular with couples in your area',
		/* eslint-disable no-template-curly-in-string */
		BOW: 'Top-rated ${categories}',
		PVREC_CROSS_CAT: 'Users who considered this ${category} also checked out',
	},
};
