import { useGPT } from '@hooks/useGPT';
import React, { type FC } from 'react';

export type GooglePublisherTagProps = CustomHooks.UseGPTParams;

const GooglePublisherTag: FC<GooglePublisherTagProps> = (props) => {
	const { id, size } = props;
	const [width, height] = size;

	useGPT({ ...props });

	return (
		<div
			id={id}
			style={{ width: `${width}px`, height: `${height}px` }}
			data-testid={`test-${id}`}
		/>
	);
};

export { GooglePublisherTag };
