import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ClientSideRenderer extends Component {
	static propTypes = {
		children: PropTypes.node,
		placeholderStyle: PropTypes.object,
		placeholderClass: PropTypes.string,
	};

	static defaultProps = {
		children: null,
		placeholderStyle: {},
		placeholderClass: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			clientSideRender: false,
		};
	}

	componentDidMount() {
		/* eslint react/no-did-mount-set-state: 0 */
		this.setState({
			clientSideRender: true,
		});
	}

	render() {
		// assuming placeholder is div can cause SSR problem. what if children is not div?
		return this.state.clientSideRender ? (
			this.props.children
		) : (
			<div
				className={this.props.placeholderClass}
				style={this.props.placeholderStyle}
			/>
		);
	}
}
