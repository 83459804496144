import cx from 'classnames';
import merge from 'lodash/merge';
import React, { FC, useContext } from 'react';
import TrackableCard from '../../../../../../components/trackable_card';
import { WidgetContext } from '../../../index';
import Styles from '../../styles.scss';
import type { CardProps } from '../../types';

const Card: FC<CardProps> = (props) => {
	const {
		cardType,
		displayTheme,
		impressionType,
		index,
		trackingData,
		vendor,
		viewport,
	} = props;
	const vendorTrackingData = merge({}, trackingData, {
		properties: { impressionPosition: index + 1 },
	});
	const cardContainerClassName = cx(
		displayTheme ? Styles[`${displayTheme}CardContainer`] : '',
		Styles[`${cardType}CardContainer`],
	);

	const getVendorCardClasses = () => {
		const vendorCardClasses: Object = displayTheme
			? { container: Styles[`${displayTheme}Card`] }
			: {};
		const contextValue = useContext(WidgetContext);

		if (contextValue === 'top') {
			return {
				...vendorCardClasses,
				'info-container': Styles.topInfoContainer,
				container: Styles.container,
			};
		}
		if (contextValue === 'right') {
			return {
				...vendorCardClasses,
				'info-container': Styles.rightInfoContainer,
			};
		}
		if (contextValue === 'center') {
			return {
				...vendorCardClasses,
				'info-container': Styles.centerInfoContainer,
				container: Styles.centerContainer,
			};
		}

		return vendorCardClasses;
	};

	const vendorCardClasses: Object = getVendorCardClasses();

	return (
		<div className={cardContainerClassName} key={vendor.id}>
			<TrackableCard
				impressionDetails={{
					locationIndex: index + 1,
					impressionType,
					city: '',
					vendor,
				}}
				key={vendor.id}
				trackingData={vendorTrackingData}
				type={cardType}
				vendor={vendor}
				viewport={viewport}
				vendorCardClasses={vendorCardClasses}
				cardProps={{
					isFeatured: false,
				}}
			/>
		</div>
	);
};

export default Card;
