import type { CategoryCode } from 'types/Category';

export interface TopTenCategory {
	/** category code */
	code: string;
	/** category name */
	name: string;
	/** category slug */
	urlSlug: string;
	/** category id */
	id: string;
}

interface CategoryConstants {
	TOP_TEN_CATEGORIES: TopTenCategory[];
}

const constants: CategoryConstants = {
	TOP_TEN_CATEGORIES: [
		{
			code: 'BWP',
			name: 'Bridal Salons',
			urlSlug: 'bridal-salons',
			id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		},
		{
			code: 'BEA',
			name: 'Beauty Salons',
			urlSlug: 'beauty-services',
			id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		},
		{
			code: 'CAT',
			name: 'Caterers',
			urlSlug: 'catering',
			id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		},
		{
			code: 'DJS',
			name: 'DJs',
			urlSlug: 'wedding-djs',
			id: '37ffe21a-579f-4100-88c1-ca664f5398ce',
		},
		{
			code: 'FLO',
			name: 'Florists',
			urlSlug: 'florists',
			id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		},
		{
			code: 'REC',
			name: 'Reception Venues',
			urlSlug: 'wedding-reception-venues',
			id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		},
		{
			code: 'VID',
			name: 'Videographers',
			urlSlug: 'wedding-videographers',
			id: '4c1e13e9-d500-4f3f-870e-d919beda43cf',
		},
		{
			code: 'BDS',
			name: 'Wedding Bands',
			urlSlug: 'live-wedding-bands',
			id: '92520084-6d29-49e9-a9ab-3b2daef7fa00',
		},
		{
			code: 'WPH',
			name: 'Wedding Photographers',
			urlSlug: 'wedding-photographers',
			id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		},
		{
			code: 'PLN',
			name: 'Wedding Planners',
			urlSlug: 'wedding-planners',
			id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		},
	],
};

export const CATEGORY_SINGULAR_ABBREVIATED_TERMS: Partial<
	Record<CategoryCode, string>
> = {
	FLO: 'florist',
	VID: 'videographer',
	WPH: 'photographer',
	REC: 'venue',
};

export default constants;
