import { formatCityStateCode } from '../../../../utils/formatUrls';

const isSearchPage = (
	pageType: Page.PageType,
	city?: string,
	state?: string,
): boolean => pageType === 'city' && !!city && !!state;

const getLinkQueryString = (sortType?: Search.SortType): string => {
	return sortType === 'recommended' ? '' : '?sort=featured';
};

export const getLinkUrl = (
	categorySlug: string,
	pageType: Page.PageType,
	sortType?: Search.SortType,
	city?: string,
	state?: string,
): string => {
	if (isSearchPage(pageType, city, state)) {
		const query = getLinkQueryString(sortType);
		const location = { city, stateCode: state };
		const path = `/marketplace/${categorySlug}-${formatCityStateCode(
			location,
		)}`;
		return `${(path + query).toLowerCase()}`;
	}

	return `/marketplace/${categorySlug}`;
};

export const getCardTitle = (
	pageType: Page.PageType,
	categoryName: string,
	city?: string,
	state?: string,
): string => {
	if (isSearchPage(pageType, city, state)) {
		return `${city} ${categoryName}`;
	}

	return city ? `${city} ${categoryName}` : categoryName;
};
