import { Body1 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { DisplayLink } from '@xo-union/tk-ui-links';
import { AnalyticsContext } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import Link from '../../link';

export class TrackableLink extends Component {
	onClickHandler = () => {
		// in some cases we need to fire two or more events from a single link
		// in which case we would pass trackingData as an array of event payloads,
		// but in others just a single event object/payload will be passed. Normalize
		// single events by putting into an array
		let trackingDataCollection = [];

		if (Array.isArray(this.props.trackingData)) {
			trackingDataCollection = this.props.trackingData;
		} else {
			trackingDataCollection.push(this.props.trackingData);
		}

		trackingDataCollection.forEach((eventData) => {
			const { event, properties } = eventData;

			this.context.track({ event, properties });
		});
	};

	hasLink() {
		return !!this.props.websiteUrl;
	}

	phoneLink() {
		return this.props.itemprop === 'telephone';
	}

	renderPhoneLink() {
		const { websiteUrl, itemprop, text } = this.props;
		return (
			<DisplayLink
				itemProp={itemprop}
				href={websiteUrl}
				onClick={this.onClickHandler}
				rel="noopener noreferrer nofollow"
			>
				<Body1 className="phone-number">{text}</Body1>
			</DisplayLink>
		);
	}

	renderLink() {
		const { websiteUrl, title, icon, iconSize, text, iconClassName } =
			this.props;
		return (
			<Link
				websiteUrl={websiteUrl}
				title={title}
				text={text}
				icon={icon}
				iconClassName={iconClassName}
				iconSize={iconSize}
				onClick={this.onClickHandler}
			/>
		);
	}

	render() {
		return (
			this.hasLink() && (
				<div>
					{this.phoneLink() ? this.renderPhoneLink() : this.renderLink()}
				</div>
			)
		);
	}
}

TrackableLink.propTypes = {
	iconClassName: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.string,
	itemprop: PropTypes.string,
	text: PropTypes.string,
	title: PropTypes.string,
	trackingData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
		.isRequired,
	vendor: PropTypes.object.isRequired,
	viewport: PropTypes.object.isRequired,
	websiteUrl: PropTypes.string.isRequired,
};

TrackableLink.defaultProps = {
	icon: '',
	iconClassName: '',
	iconSize: 'md',
	itemprop: '',
	text: null,
	title: '',
};

TrackableLink.contextType = AnalyticsContext;

export default TrackableLink;
