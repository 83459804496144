import React, { Fragment, memo, FC } from 'react';
import SkeletonCards from '../skeletonCards';
import type { CardProps } from '../types';
import Card from './components/Card';
import CardColumn from './components/CardColumn';

// All props are the same as CardProps except for vendor, since this is a collection.
export interface RecommendationsProps
	extends Omit<CardProps, 'index' | 'vendor'> {
	recommendedVendors: Vendor.Raw[];
	widgetName: string;
}

const Recommendations: FC<RecommendationsProps> = (props) => {
	const { recommendedVendors, widgetName, ...cardProps } = props;
	const { slider } = cardProps;
	const recommendedVendorsToDisplay =
		widgetName === 'lite-storefront'
			? recommendedVendors.slice(0, 3)
			: recommendedVendors;

	if (recommendedVendors.length === 0) {
		return <SkeletonCards />;
	}

	return (
		<Fragment>
			{recommendedVendorsToDisplay.map((vendor, index) =>
				slider ? (
					<Card {...cardProps} index={index} vendor={vendor} />
				) : (
					<CardColumn {...cardProps} index={index} vendor={vendor} />
				),
			)}
		</Fragment>
	);
};

// Do not re-render if recommendations are the same
export const recommendationsAreEqual = (
	previousProps: RecommendationsProps,
	nextProps: RecommendationsProps,
) => {
	const { recommendedVendors: previousVendors } = previousProps;
	const { recommendedVendors: nextVendors } = nextProps;

	return (
		previousVendors.length === nextVendors.length &&
		nextVendors.every(
			(r) => previousVendors.findIndex((previous) => previous.id === r.id) > -1,
		)
	);
};

export default memo(Recommendations, recommendationsAreEqual);
