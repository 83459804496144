import { Logger } from '@utils/logger/logger';
import conversations from '../../../api/conversations';
import { fetchConversations, setConversationsTimestamps } from './actionTypes';

export const getConversations =
	(memberId: string, sessionToken: string): Redux.ThunkResult<Promise<void>> =>
	async (dispatch) => {
		try {
			const memberConversations = (await conversations(
				memberId,
				sessionToken,
			)) as Conversation.Standard[];

			const convos = memberConversations
				.map((convo) => ({ [convo.storefrontInfo.id]: convo.id }))
				.reduce((acc, cv) => {
					const [[vendorId, convoId]] = Object.entries(cv);
					return {
						...acc,
						[vendorId]: convoId,
					};
				}, {});

			const newTimestamps = memberConversations.map((convo) => {
				return {
					vendorId: convo.storefrontInfo.id,
					conversationCreatedAt: convo.timestamp.createdAt,
				};
			});

			dispatch(fetchConversations(convos));
			dispatch(setConversationsTimestamps(newTimestamps));
		} catch (error) {
			Logger.error({
				log_message:
					'Conversations API call within messagedVendors thunks failed',
				errorToLog: error,
				newRelic: true,
			});
		}
	};
