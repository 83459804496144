import Icon from '@xo-union/tk-component-icons';
import { H3 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './styles.scss';

const FacetWithIcon = ({ label = '', icon = '' }) => {
	if (label && icon) {
		return (
			<span className={Styles.facetIconContainer}>
				<H3 className={Styles.icon}>
					<Icon name={icon} />
				</H3>
				<span className={Styles.label}>{label}</span>
			</span>
		);
	}
	return null;
};

FacetWithIcon.defaultProps = {
	icon: '',
	label: '',
};

FacetWithIcon.propTypes = {
	icon: PropTypes.string,
	label: PropTypes.string,
};

FacetWithIcon.defaultProps = {
	icon: null,
	label: null,
};

export default FacetWithIcon;
