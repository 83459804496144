import { Row } from '@xo-union/tk-component-grid';
import React, { type FC } from 'react';
import Recommendations from '..';
import type { CardContainerProps } from '../../types';

import Styles from '../../styles.scss';

type ListProps = Omit<CardContainerProps, 'algorithmType'>;

const List: FC<ListProps> = (props) => (
	<Row className={Styles.recs}>
		<Recommendations {...props} />
	</Row>
);

export default List;
