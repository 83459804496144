export default {
	SIMILAR: {
		name: 'similarity',
		position: 'top',
		variationAlgorithm: 'none',
	},
	BOW: {
		name: 'bow',
		position: 'right',
		variationAlgorithm: 'none',
	},
	PVREC_ANON: {
		name: 'pvrec anon',
		position: 'right',
		variationAlgorithm: 'none',
	},
	PVREC_CROSS_CAT: {
		name: 'pvrec cross category',
		position: 'middle',
		variationAlgorithm: 'none',
	},
};
