import Vendor from '../../../../decorators/vendor';
import algorithms from '../../constants/algorithms';

const bow = (vendor) => {
	// breadcrumbInfo always inserts the sort parameter. Reference app/lib/v3/vendor_attribute_mapper.rb
	const operator = vendor.breadcrumbInfo.cityUrl.includes('?') ? '&' : '?';
	return `${vendor.breadcrumbInfo.cityUrl}${operator}best-of-weddings=current-winners`;
};

const crossCat = (vendor, rawRecommendedVendor) => {
	const recommendedVendor = new Vendor(rawRecommendedVendor);
	return vendor.breadcrumbInfo.cityUrl.replace(
		vendor.categorySlug,
		recommendedVendor.categorySlug,
	);
};
export default (algorithm, vendor, rawRecommendedVendor) => {
	switch (algorithm) {
		case algorithms.BOW:
			return bow(vendor);
		case algorithms.PVREC_CROSS_CAT:
			return crossCat(vendor, rawRecommendedVendor);
		default:
			return vendor.breadcrumbInfo.cityUrl;
	}
};
