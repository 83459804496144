import { UnionConsentManagementMock } from '@-test-support/web/UnionConsentManagement/mocks';
import { UnionConsentManagement } from '@xo-union/consent-management';
import { useEffect, useRef, useState } from 'react';
import { handleSlotRenderEnded } from './utils';

type WindowGoogleTag = typeof googletag | { cmd: googletag.CommandArray };

const useGPT = ({
	destroyAdOnCleanup = false,
	id,
	setBuzzBadgeIds,
	setIsAdEmpty,
	size,
	targeting,
}: CustomHooks.UseGPTParams) => {
	const [isSlotCreated, setIsSlotCreated] = useState(false);
	const slotRef = useRef<null | googletag.Slot>(null);

	useEffect(() => {
		(window.googletag as WindowGoogleTag) = window.googletag || { cmd: [] };
		(window.UnionConsentManagement as UnionConsentManagement) =
			window.UnionConsentManagement;

		googletag.cmd.push(() => {
			const restrictDataProcessing = window.UnionConsentManagement
				?.consentPlatformEnabled
				? !window.UnionConsentManagement.consentedTo('Targeting')
				: false;
			googletag.pubads().setPrivacySettings({
				restrictDataProcessing,
			});

			if (!isSlotCreated) {
				slotRef.current = googletag.defineSlot(
					'/4879/marketplace.n_tk',
					size,
					id,
				);
				setIsSlotCreated(true);
			} else {
				slotRef.current?.addService(
					googletag.pubads().addEventListener('slotRenderEnded', (evt) =>
						handleSlotRenderEnded({
							evt,
							setBuzzBadgeIds,
							setIsAdEmpty,
							slotRef,
						}),
					),
				);

				Object.entries(targeting).forEach(([key, value]) => {
					slotRef.current?.setTargeting(key, value);
				});

				googletag.enableServices();
				googletag.display(id);
			}
		});

		return () => {
			if (isSlotCreated && destroyAdOnCleanup && slotRef.current) {
				googletag.destroySlots([slotRef.current]);
			}
		};
	}, [isSlotCreated]);
};

export { useGPT };
