import React, { FunctionComponent, Fragment } from 'react';
import { Bone, Frame } from '../../../../components/shared/Skeleton';

const SkeletonCards: FunctionComponent = () => (
	<Fragment>
		{Array.from({ length: 4 }).map((_, i) => (
			<Frame
				key={i}
				ariaLabel="Loading Recommendation..."
				style={{ marginBottom: '30px', marginRight: '15px', width: '100%' }}
			>
				<div style={{ display: 'flex', width: '100%' }}>
					<Bone style={{ height: '90px', width: '90px' }} />
					<div style={{ padding: '4px 12px 0', flex: '1' }}>
						<Bone
							style={{ height: '20px', width: '100%', marginBottom: '2px' }}
						/>
						<Bone
							style={{ height: '16px', width: '85px', marginBottom: '2px' }}
						/>
						<Bone
							style={{ height: '16px', width: '85px', marginBottom: '2px' }}
						/>
						<Bone style={{ height: '16px', width: '100%' }} />
					</div>
				</div>
			</Frame>
		))}
	</Fragment>
);

export default SkeletonCards;
