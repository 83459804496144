import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { TopLevelContainer } from '@xo-union/tk-component-grid';
import React from 'react';

import Styles from './styles.scss';

const RequestQuote = () => (
	<TopLevelContainer
		classes={compose({ 'top-level-container': Styles.requestContainer })}
	>
		<Button size="md" color="primary" block>
			Request Quote
		</Button>
	</TopLevelContainer>
);

export default RequestQuote;
